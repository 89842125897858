/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext, useState } from 'react'
import * as gtag from 'lib/gtag'

type TriggeredLanguageRegionModal = 'header' | 'footer' | null

type ModalStateContextType = {
  showGetAppModal: boolean
  setShowGetAppModal: Dispatch<SetStateAction<boolean>>
  showGetNewsletterModal: boolean
  setShowGetNewsletterModal: Dispatch<SetStateAction<boolean>>
  showGetStoriesModal: boolean
  setShowGetStoriesModal: Dispatch<SetStateAction<boolean>>
  showGetAppModalFromProductPage: boolean
  setShowGetAppModalFromProductPage: Dispatch<SetStateAction<boolean>>
  showLanguageRegionModal: boolean
  setShowLanguageRegionModal: Dispatch<SetStateAction<boolean>>
  openLanguageRegionModal: (componentName: TriggeredLanguageRegionModal) => void
  closeLanguageRegionModal: (madeChanges: boolean) => void
  openedLanguageRegionModal: TriggeredLanguageRegionModal
  closedLanguageRegionModal: TriggeredLanguageRegionModal
}

const ModalStateContextDefaultValues: ModalStateContextType = {
  showGetAppModal: false,
  setShowGetAppModal: () => {},
  showGetNewsletterModal: false,
  setShowGetNewsletterModal: () => {},
  showGetStoriesModal: false,
  setShowGetStoriesModal: () => {},
  showGetAppModalFromProductPage: false,
  setShowGetAppModalFromProductPage: () => {},
  showLanguageRegionModal: false,
  setShowLanguageRegionModal: () => {},
  openLanguageRegionModal: () => {},
  closeLanguageRegionModal: () => {},
  openedLanguageRegionModal: null,
  closedLanguageRegionModal: null,
}

const ModalStateContext = createContext<ModalStateContextType>(ModalStateContextDefaultValues)

export function useModalState(): ModalStateContextType {
  return useContext(ModalStateContext)
}

type PropTypes = {
  children: ReactNode
}

export const ModalStateProvider: FC<PropTypes> = ({ children }) => {
  const [showGetAppModal, setShowGetAppModal] = useState<boolean>(false)
  const [showGetNewsletterModal, setShowGetNewsletterModal] = useState<boolean>(false)
  const [showGetStoriesModal, setShowGetStoriesModal] = useState<boolean>(false)
  const [showGetAppModalFromProductPage, setShowGetAppModalFromProductPage] = useState<boolean>(false)
  const [showLanguageRegionModal, setShowLanguageRegionModal] = useState<boolean>(false)
  const [openedLanguageRegionModal, setOpenedLanguageRegionModal] = useState<TriggeredLanguageRegionModal>(null)
  const [closedLanguageRegionModal, setClosedLanguageRegionModal] = useState<TriggeredLanguageRegionModal>(null)

  const openLanguageRegionModal = (componentName: TriggeredLanguageRegionModal) => {
    setOpenedLanguageRegionModal(componentName)
    setShowLanguageRegionModal(true)

    switch (componentName) {
      case 'footer':
        gtag.logEvent('langpicker_footer', { action: 'open' })
        break
      case 'header':
        gtag.logEvent('langpicker_header', { action: 'open' })
        break
      default:
        gtag.logEvent('langpicker_unknown', { action: 'open' })
        break
    }
  }

  const closeLanguageRegionModal = (madeChanges: boolean) => {
    setClosedLanguageRegionModal(openedLanguageRegionModal)
    setShowLanguageRegionModal(false)

    if (!madeChanges) {
      switch (openedLanguageRegionModal) {
        case 'footer':
          gtag.logEvent('langpicker_footer', { action: 'close_without_changing_anything' })
          break
        case 'header':
          gtag.logEvent('langpicker_header', { action: 'close_without_changing_anything' })
          break
        default:
          gtag.logEvent('langpicker_unknown', { action: 'close_without_changing_anything' })
          break
      }
    }
  }

  const value = {
    showGetAppModal,
    setShowGetAppModal,
    showGetNewsletterModal,
    setShowGetNewsletterModal,
    showGetStoriesModal,
    setShowGetStoriesModal,
    showGetAppModalFromProductPage,
    setShowGetAppModalFromProductPage,
    showLanguageRegionModal,
    setShowLanguageRegionModal,
    openLanguageRegionModal,
    closeLanguageRegionModal,
    openedLanguageRegionModal,
    closedLanguageRegionModal,
  }

  return (
    <>
      <ModalStateContext.Provider value={value}>{children}</ModalStateContext.Provider>
    </>
  )
}
