import React, { AllHTMLAttributes, FC } from 'react'

import { Sprinkles, sprinkles } from 'atomic/styles/sprinkles.css'
import { parseSprinkleProps } from '../helpers/sprinkleHelper'
import * as css from './button.css'
import { ouTheme, textMaxWidth } from 'atomic/styles/theme.css'
import { Box, Container } from 'atomic'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

export type ButtonType = keyof typeof ouTheme.buttonTypes
export type ButtonSize = 'xs' | 's' | 'm' | 'l'

export type ButtonExtraProps = {
  variant?: ButtonType
  size?: ButtonSize
  children?: React.ReactNode
  blogButtonPosition?: 'left' | 'center' | 'right' | 'none'
  elevation?: boolean
} & BuilderProps

export type ButtonProps = Omit<
  AllHTMLAttributes<HTMLButtonElement>,
  'className' | 'content' | 'translate' | 'color' | 'background' | 'size' | 'width' | 'cursor'
> &
  Omit<Sprinkles, 'background' | 'paddingY' | 'paddingX'> & {
    className?: string
  } & ButtonExtraProps

const Button: FC<ButtonProps> = ({
  children,
  variant = 'primary',
  size = 'm',
  borderRadius = 'xxl',
  className = '',
  blogButtonPosition,
  elevation = false,
  ...props
}) => {
  const { sprinkleProps, otherProps } = parseSprinkleProps({ ...props, borderRadius })

  let blogButtonFlexPosition
  switch (blogButtonPosition) {
    case 'left':
      blogButtonFlexPosition = 'flex-start'
      break
    case 'center':
      blogButtonFlexPosition = 'center'
      break
    case 'right':
      blogButtonFlexPosition = 'flex-end'
      break
  }
  // Remove Builder.io specific props
  const cleanProps = removeKeys(otherProps, { builderBlock: true, builderState: true })

  return (
    <>
      {!blogButtonPosition || blogButtonPosition === 'none' ? (
        <button
          className={`${className} ${css.btn[elevation ? 'elevation' : 'default']} ${css.btnTheme[variant]} ${css.btnSize[size]} ${sprinkles({
            ...sprinkleProps,
          })}`}
          {...cleanProps}
        >
          {children}
        </button>
      ) : (
        <Container
          paddingLeft={'none'}
          paddingRight={'none'}
          style={{
            maxWidth: textMaxWidth,
            width: '100%',
            display: 'flex',
          }}
          className={css.blogButtonContainer}
          justifyContent={blogButtonFlexPosition}
        >
          <Box>
            <button
              className={`${className} ${css.btn[elevation ? 'elevation' : 'default']} ${css.btnTheme[variant]} ${css.btnSize[size]} ${sprinkles({ ...sprinkleProps })}`}
              {...cleanProps}
            >
              {children}
            </button>
          </Box>
        </Container>
      )}
    </>
  )
}

export default Button
