import { createContext, FC, ReactNode, useContext } from 'react'
import ChristmasDeliveryDate from '../types/ChristmasDeliveryDates'

type BackendContextType = {
  christmasDeliveryDates: ChristmasDeliveryDate
}

const BackendContextDefaultValues: BackendContextType = {
  christmasDeliveryDates: null,
}

const BackendContext = createContext<BackendContextType>(BackendContextDefaultValues)

export function useBackend(): BackendContextType {
  return useContext(BackendContext)
}

type PropTypes = {
  children: ReactNode
  christmasDeliveryDates: ChristmasDeliveryDate
}

export const BackendProvider: FC<PropTypes> = ({ children, christmasDeliveryDates }) => {
  const value = {
    christmasDeliveryDates,
  }

  return (
    <>
      <BackendContext.Provider value={value}>{children}</BackendContext.Provider>
    </>
  )
}
