import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'

import { getJwtToken } from '../lib/firebase'

const url = process.env.NEXT_PUBLIC_APPSYNC_URL
const region = process.env.NEXT_PUBLIC_APPSYNC_REGION

const auth: AuthOptions = {
  type: AUTH_TYPE.OPENID_CONNECT,
  jwtToken: getJwtToken,
}

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, new HttpLink({ uri: url, fetch })),
])

// Global variable to hold the Apollo Client instance
let apolloClient: ApolloClient<unknown> | null = null

export const getApolloClient = async (): Promise<ApolloClient<unknown>> => {
  if (apolloClient) return apolloClient
  const cache = new InMemoryCache()

  if (typeof window !== 'undefined') {
    try {
      await persistCache({
        cache,
        storage: new LocalStorageWrapper(window.localStorage),
      })
    } catch (error) {}
  }

  apolloClient = new ApolloClient({
    link,
    cache,
  })

  return apolloClient
}
