/* eslint-disable @typescript-eslint/no-explicit-any */
// lib/gtag.ts

// Replace these with your actual IDs or load them from environment variables
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || 'G-XXXXXXXXXX' // GA4 Measurement ID
export const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-XXXXXXX' // GTM Container ID

// Extend the Window interface for TypeScript
declare global {
  interface Window {
    dataLayer: any[]
    gtag: (...args: any[]) => void
  }
}

// Initialize dataLayer and gtag if they aren't already available
if (typeof window !== 'undefined' && !window.gtag) {
  window.dataLayer = window.dataLayer || []
  window.gtag = (...args: any[]) => {
    window.dataLayer.push(args)
  }
}

/**
 * Sets the default consent settings for various storage types.
 * This should be called once gtag is available.
 */
export const setDefaultConsent = (): void => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
      personalization_storage: 'denied',
      functionality_storage: 'denied',
      security_storage: 'denied',
    })
  }
}

/**
 * Log a pageview with GA4.
 * This function can be used for direct GA4 logging.
 *
 * @param url - The URL of the page.
 */
export const pageview = (url: string): void => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    })
  }
}

/**
 * Log an event directly using GA4.
 * This is useful for custom events that you want to send directly.
 *
 * @param action - The event name.
 * @param params - An object containing parameters for the event.
 *
 * Example usage: gtag.logEvent('top_banner_click', { data_type: dataType, data_value: dataValue })
 */
export const logEvent = (action: string, params: { [key: string]: any } = {}): void => {
  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', action, params)

    // Use this instead to run in DebugView mode in GA4
    /* window.gtag('event', action, {
      ...params,
      debug_mode: true, // Enables Debug Mode in GA4
    }) */
  }
}

export const trackOutboundLink = (url: string): void => {
  if (!url) return

  if (typeof window?.gtag !== 'undefined') {
    gtag('event', 'outbound', { click: url })
  }
}
