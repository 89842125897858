import NextLink, { LinkProps } from 'next/link'
import { FC /* , useEffect, useState */ } from 'react'
import { useRouter } from 'next/router'
import { trackOutboundLink } from 'lib/analytics-utils'
import { getPreservedUrl } from 'utils/preserve-query'
import { useCookieContext } from 'context/CookieProvider'

type CustomLinkProps = Omit<LinkProps, 'href'> & {
  href: string
} & React.AnchorHTMLAttributes<HTMLAnchorElement> &
  LinkProps

/**
 * A custom Next.js `<Link>` component that preserves URL query parameters and tracks outbound links.
 * Use for internal links or links that navigate within the same domain or to one of our onelinks.
 * Other external links should use a regular anchor tag.
 *
 * - Automatically appends existing query parameters to the `href` when navigating within the same domain.
 * - Calls `trackOutboundLink` when clicked to monitor external link interactions.
 *
 * @param href - The destination URL.
 * @param props - Additional properties passed to the Next.js `<Link>` component.
 * @returns A `<Link>` component with the preserved query parameters and tracking functionality.
 */
export const Link: FC<CustomLinkProps> = ({ href, ...props }) => {
  const router = useRouter()

  // Track if the component has mounted on the client
  // const [isMounted, setIsMounted] = useState(false)
  const { cookies } = useCookieContext()

  /*   // This will run once after the component mounts on the client
  useEffect(() => {
    setIsMounted(true)
  }, [])

  // Early return if component hasn't mounted (avoid mismatch)
  if (!isMounted) {
    return null
  } */

  const preservedHref = getPreservedUrl(href as string, router.asPath, cookies)

  const onClick = (): void => {
    trackOutboundLink(preservedHref)
  }

  // If the link is to onceupon.photo or a onelink, use a regular anchor tag, as it counts as an external link
  if (href.indexOf('app.onceupon.photo') > -1 || href.indexOf('onceuponapp.onelink') > -1) {
    return (
      <a href={preservedHref} onClick={onClick} {...props}>
        {props.children}
      </a>
    )
  }

  return <NextLink onClick={onClick} href={preservedHref} {...props} />
}
