import '../styles/globals.scss'
import { appWithTranslation, useTranslation } from 'next-i18next'
import { withTranslateRoutes } from 'next-translate-routes'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { FC, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { BackendProvider } from '../context/BackendContext'
import { JobylonProvider } from '../context/JobylonListContext'
import { LinkProvider } from '../context/LinkContext'
import { ModalStateProvider } from '../context/ModalStateContext'
import { theme } from '../styles/Theme'

import type { AppProps } from 'next/app'
import registerBuilderComponents from 'utils/register-builder-components'
import { builder } from '@builder.io/sdk'
import CookieConsentBanner from 'components/cookiebanner/CookieBanner'
import { CookiesProvider } from 'react-cookie'
import * as gtag from '../lib/gtag'

import { ApolloProvider } from '@apollo/client'
import { getApolloClient } from 'utils/apollo-client'

builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY)
registerBuilderComponents()

import { Noto_Sans } from 'next/font/google'
import { GTM_CONTAINER_ID } from '../lib/gtag'
import { CookieProvider } from 'context/CookieProvider'

const noto = Noto_Sans({
  weight: ['200', '300', '400', '500', '600', '800'],
  style: ['normal'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--primaryFont',
})

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter()
  const { i18n } = useTranslation()
  const [client, setClient] = useState(null)

  useEffect(() => {
    i18n.changeLanguage(router.locale)
  }, [router.locale])

  useEffect(() => {
    const initializeClient = async () => {
      const apolloClient = await getApolloClient()
      setClient(apolloClient)
    }

    initializeClient()

    const handleRouteChange = (url): void => {
      gtag.setDefaultConsent()
      gtag.pageview(url)
      const storage = globalThis?.sessionStorage

      if (!storage) return
      // Set the previous path as the value of the current path.
      const prevPath = storage.getItem('currentPath')
      storage.setItem('prevPath', prevPath)
      // Set the current path value by looking at the browser's location object.
      storage.setItem('currentPath', url)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  const domain = process.env.NEXT_PUBLIC_VERCEL_ENV !== undefined ? 'onceupon.photo' : 'localhost'

  return (
    <>
      {/* Load GTM dynamically after GA4 */}
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${GTM_CONTAINER_ID}');
          `,
        }}
      />
      <div className={noto.className}>
        {wrapWithApollo(
          client,
          <CookiesProvider defaultSetOptions={{ path: '/', domain, maxAge: 365 * 24 * 60 * 60 }}>
            <CookieProvider>
              <CookieConsentBanner />
              <ThemeProvider theme={theme}>
                <LinkProvider>
                  <ModalStateProvider>
                    <JobylonProvider jobylonlist={pageProps.jobs}>
                      <BackendProvider christmasDeliveryDates={pageProps.christmasDeliveryDates}>
                        <Component {...pageProps} />
                      </BackendProvider>
                    </JobylonProvider>
                  </ModalStateProvider>
                </LinkProvider>
              </ThemeProvider>
              <Script src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
            </CookieProvider>
          </CookiesProvider>
        )}
      </div>
    </>
  )
}

// Helper function for conditional ApolloProvider
const wrapWithApollo = (client, children) => (client ? <ApolloProvider client={client}>{children}</ApolloProvider> : children)

export default appWithTranslation(withTranslateRoutes(JSON.parse(`{"defaultLocale":"en-gb","locales":["da-dk","de-de","en-au","en-gb","en-us","en-ca","es-es","fr-fr","it-it","nl-nl","nb-no","sv-se"],"routesTree":{"name":"start-creating-photobooks","paths":{"default":"start-creating-photobooks","sv-se":"borja-skapa-fotobocker"}},"fallbackLng":{}}`), MyApp))
