import { Builder, withChildren } from '@builder.io/react'
import { illustrationTypes } from 'atomic/components/atoms/illustrations/Illustration'
import { kluddIllustrationTypes } from 'atomic/components/atoms/illustrations/KluddIllustration'
import { ouTheme, themeVars } from 'atomic/styles/theme.css'
import { arrowIllustrationTypes } from 'atomic/components/atoms/illustrations/ArrowIllustration'
import { socialMediaIconTypes } from 'atomic/components/atoms/illustrations/SocialMediaIcon'
import dynamic from 'next/dynamic'

const ALLOWED_IMAGE_FILE_TYPES = ['jpeg', 'png', 'jpg', 'gif', 'webp']
const ALLOWED_VIDEO_FILE_TYPES = ['mp4', 'mov', 'webm']
const PRODUCT_TYPES = ['book', 'print']
const OU_COLORS = ouTheme.colors

const MARGIN_PADDING_INPUTS = [
  { name: 'margin', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
  { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], defaulValue: 'xxxl' },
  { name: 'marginTop', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
  { name: 'marginLeft', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
  { name: 'marginRight', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
  { name: 'padding', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  { name: 'paddingLeft', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  { name: 'paddingRight', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  { name: 'paddingTop', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  { name: 'paddingBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
]
const TEXT_INPUTS = [
  { name: 'fontSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], advanced: true },
  { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], advanced: true },
  { name: 'color', type: 'string', enum: [...Object.keys(OU_COLORS)], advanced: true },
]
const LAYOUT_INPUTS = [...MARGIN_PADDING_INPUTS, ...TEXT_INPUTS]

const IMAGE_ALT_TEXT_INPUT = [
  {
    name: 'altText',
    helperText: 'REQUIRED for accessibility, the more detailed info of the image, the better.',
    type: 'text',
    required: true,
  },
]

export default function registerBuilderComponents(): void {
  Builder.registerComponent(
    dynamic(() => import('components/builder_components/blog_components/blog_hero/BlogHero')),
    {
      name: 'StoriesAboveTheFold',
      friendlyName: 'Hero',
      models: ['blog-article'],
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'fullBleedLayout', friendlyName: 'Use full bleed layout', type: 'boolean', required: false, defaultValue: false },
        { name: 'title', type: 'text', required: true },
        { name: 'intro', type: 'longText', required: true },
        { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, required: true },
        {
          name: 'altText',
          helperText:
            'REQUIRED for accessibility, the more detailed info of the image, the better. Leave empty if you want to use the default "Photo books" alt text.',
          type: 'text',
        },
        {
          name: 'imagePosition',
          type: 'string',
          enum: ['center', 'top', 'left', 'right', 'bottom', 'top left', 'top right', 'bottom left', 'bottom right'],
          required: false,
          defaultValue: 'center',
        },
        { name: 'videoCheck', friendlyName: 'Use video instead of image', type: 'boolean', required: false, defaultValue: false },
        {
          name: 'videoSettings',
          type: 'object',
          showIf: `options.get('videoCheck') === true`,
          subFields: [
            { name: 'videosrc', friendlyName: 'Video', type: 'file', allowedFileTypes: ALLOWED_VIDEO_FILE_TYPES, required: true },
            { name: 'loop', type: 'boolean', defaultValue: true },
            { name: 'muted', type: 'boolean', defaultValue: true },
            { name: 'fileType', type: 'string', enum: ['mp4', 'mov', 'webm'], defaultValue: 'mp4' },
          ],
        },
        { name: 'guestAuthor', friendlyName: 'Add Guest Author', type: 'boolean', required: false, defaultValue: false },
        {
          name: 'guestAuthorSettings',
          friendlyName: 'Fill in Guest author details',
          helperText: 'This information will only be shown for this story. It will not be saved for future use.',
          type: 'object',
          showIf: `options.get('guestAuthor') === true`,
          subFields: [
            { name: 'name', type: 'text', required: true },
            { name: 'email', type: 'text' },
            { name: 'photo', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
            {
              name: 'altText',
              helperText:
                'REQUIRED for accessibility, the more detailed info of the image, the better. Leave empty if you want to use the default alt text.',
              type: 'text',
            },
          ],
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/page_hero/PageHero')),
    {
      name: 'HeroGeneric',
      friendlyName: 'Hero',
      models: ['page'],
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        {
          name: 'layout',
          helperText:
            'Choose between three different types of layout. Default and fullbleed is used for all pages and "Illustration" is mainly for the help pages.',
          type: 'string',
          enum: ['default', 'fullbleed', 'illustration', 'product'],
          defaultValue: 'default',
          required: true,
        },
        { name: 'useFullbleedLayout', type: 'boolean', defaultValue: false, showIf: `options.get('layout') === 'illustration'` },
        { name: 'productType', type: 'string', enum: [...PRODUCT_TYPES], showIf: `options.get('layout') === 'product'` },
        { name: 'heading', type: 'text', showIf: `options.get('useRichTextHeading') === false` },
        {
          name: 'headingLineHeight',
          type: 'string',
          enum: [...Object.keys(themeVars.lineHeights)],
          defaultValue: 'xs',
          showIf: `options.get('layout') === 'fullbleed'`,
        },
        {
          name: 'useRichTextHeading',
          friendlyName: 'Use Rich Text editor',
          type: 'boolean',
          defaultValue: false,
          showIf: `options.get('layout') === 'fullbleed'`,
        },
        { name: 'richTextHeading', friendlyName: 'Heading', type: 'richText', showIf: `options.get('useRichTextHeading') === true` },
        {
          name: 'uppercase',
          friendlyName: 'Make heading uppercase',
          type: 'boolean',
          defaultValue: false,
          showIf: `options.get('layout') === 'fullbleed'`,
        },
        { name: 'headingSize', friendlyName: 'Font size heading', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'xxl' },
        { name: 'intro', type: 'longText', showIf: `options.get('useRichTextIntro') === false` },
        { name: 'useRichTextIntro', friendlyName: 'Use Rich Text editor', type: 'boolean', defaultValue: false },
        { name: 'richTextIntro', friendlyName: 'Intro', type: 'richText', showIf: `options.get('useRichTextIntro') === true` },
        {
          name: 'introSize',
          friendlyName: 'Font size intro',
          type: 'string',
          enum: [...Object.keys(themeVars.fontSizes)],
          defaultValue: 'l',
        },
        {
          name: 'useVideo',
          friendlyName: 'Use video instead',
          type: 'boolean',
          defaultValue: false,
          showIf: `options.get('layout') === 'fullbleed'`,
        },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('layout') === 'default' || (options.get('layout') === 'fullbleed' && options.get('useVideo') !== true)`,
        },
        {
          name: 'altText',
          helperText: 'REQUIRED for accessibility, the more detailed info of the image, the better.',
          type: 'text',
          required: true,
          showIf: `options.get('layout') === 'default' || (options.get('layout') === 'fullbleed' && options.get('useVideo') !== true)`,
        },
        {
          name: 'videoSettings',
          type: 'object',
          showIf: `options.get('useVideo') === true && options.get('layout') === 'fullbleed'`,
          subFields: [
            { name: 'videosrc', type: 'file', allowedFileTypes: ALLOWED_VIDEO_FILE_TYPES },
            { name: 'loop', type: 'boolean' },
            { name: 'muted', type: 'boolean' },
            { name: 'fileType', type: 'string', enum: ['mp4', 'mov', 'webm'], defaultValue: 'mp4' },
            { name: 'posterImage', helperText: 'Image to show when video is loading', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
            { name: 'posterMobileImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, required: false },
          ],
        },
        { name: 'overlayOpacity', type: 'range', min: 0, max: 100, defaultValue: 20, showIf: `options.get('layout') === 'fullbleed'` },
        {
          name: 'imagePosition',
          type: 'string',
          enum: ['center', 'top', 'bottom', 'left', 'right'],
          defaultValue: 'center',
          showIf: `options.get('layout') === 'default' || (options.get('layout') === 'fullbleed' && options.get('useVideo') !== true)`,
        },
        {
          name: 'detailedImagePosition',
          helperText: 'Add a number 1-100 to position the image more precist. The number is in percent and is linked to your image position.',
          type: 'number',
          showIf: `options.get('layout') === 'default' || (options.get('layout') === 'fullbleed' && options.get('useVideo') !== true)`,
        },
        {
          name: 'illustration',
          type: 'string',
          enum: [...illustrationTypes],
          defaultValue: 'heart-hug',
          showIf: `options.get('layout') === 'illustration'`,
        },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], showIf: `options.get('layout') === 'illustration'` },
        { name: 'useButton', type: 'boolean', defaultValue: false, showIf: `options.get('layout') !== 'product'` },
        {
          name: 'button',
          type: 'object',
          subFields: [
            { name: 'label', type: 'text' },
            { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)] },
            { name: 'link', type: 'string' },
            { name: 'size', type: 'string', enum: ['xs', 's', 'm', 'l'] },
            { name: 'darkMode', type: 'boolean', defaultValue: false },
          ],
          showIf: `options.get('useButton') === true`,
        },
        { name: 'addQuote', type: 'boolean', defaultValue: false, showIf: `options.get('layout') === 'default'` },
        {
          name: 'quote',
          friendlyName: 'Quote settings',
          helperText: 'Add a quote to the hero image',
          type: 'object',
          showIf: `options.get('addQuote') === true`,
          subFields: [
            { name: 'text', type: 'longText', defaultValue: 'This is the qoute' },
            { name: 'signature', type: 'text', defaultValue: 'Lina Andersson, CEO Once Upon' },
            { name: 'color', friendlyName: 'Text color', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'white' },
          ],
        },
        {
          name: 'addBadge',
          friendlyName: 'Add Customer Satisfaction badge',
          type: 'boolean',
          defaultValue: false,
          showIf: `options.get('layout') === 'illustration'`,
        },
        {
          name: 'mirroredLayout',
          type: 'boolean',
          helperText: 'Toggle this if you want the image to appear on the right side and the text on the left side',
          showIf: `options.get('layout') === 'illustration'`,
          defaultValue: false,
        },
        { name: 'startCreateButtonText', friendlyName: 'Create button text', type: 'text', showIf: `options.get('layout') === 'product'` },
        {
          name: 'heroSlides',
          friendlyName: 'Hero images',
          type: 'list',
          defaultValue: [],
          subFields: [{ name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES }, ...IMAGE_ALT_TEXT_INPUT],
          showIf: `options.get('layout') === 'product'`,
        },
        {
          name: 'infoList',
          friendlyName: 'Info list background color',
          helperText: 'This is for the list where we outline the book types, paper types, book sizes etc.',
          type: 'string',
          defaultValue: 'mossa4',
          enum: [...Object.keys(OU_COLORS)],
          showIf: `options.get('layout') === 'product'`,
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/create_cards/CreateCards')),
    {
      name: 'CreateCards',
      models: ['page'],
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        {
          name: 'photobooks',
          friendlyName: 'Photobooks Card',
          type: 'object',
          subFields: [
            { name: 'title', type: 'text', helperText: 'This is where you write your heading, leave empty for translated text' },
            { name: 'link', type: 'text', defaultValue: '/photobooks', required: true },
            { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'hjortron2' },
            { name: 'coverImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, required: true },
            { name: 'hoverImage', friendlyName: 'Mouse over image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, required: true },
          ],
          defaultValue: { title: '', link: '/photobooks', color: 'hjortron2', coverImage: '', hoverImage: '' },
        },
        {
          name: 'prints',
          friendlyName: 'Prints Card',
          type: 'object',
          subFields: [
            { name: 'title', type: 'text', helperText: 'This is where you write your heading, leave empty for translated text' },
            { name: 'link', type: 'text', defaultValue: '/prints', required: true },
            { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'hjortron3' },
            { name: 'coverImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, required: true },
            { name: 'hoverImage', friendlyName: 'Mouse over image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, required: true },
          ],
          defaultValue: { title: '', link: '/photoprints', color: 'hjortron3', coverImage: '', hoverImage: '' },
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/usp/Usp')),
    {
      name: 'SellingPointsSection',
      friendlyName: 'USP',
      models: ['page'],
      inputs: [
        { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
        {
          name: 'usp1',
          friendlyName: 'USP 1',
          type: 'object',
          defaultValue: { color: 'lav4', title: '', body: '', kluddType: 'kludd-4', kluddWidth: 70, kluddColor: 'lav3', illustration: 'plant' },
          subFields: [
            { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'title', type: 'text' },
            { name: 'body', type: 'longText' },
            { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
            { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'kluddWidth', type: 'number' },
            { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          ],
        },
        {
          name: 'usp2',
          friendlyName: 'USP 2',
          type: 'object',
          defaultValue: {
            color: 'lav4',
            title: '',
            body: '',
            kluddType: 'kludd-4',
            kluddWidth: 70,
            kluddColor: 'lav3',
            illustration: 'delivery',
          },
          subFields: [
            { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'title', type: 'text' },
            { name: 'body', type: 'longText' },
            { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
            { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'kluddWidth', type: 'number' },
            { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          ],
        },
        {
          name: 'usp3',
          friendlyName: 'USP 3',
          type: 'object',
          defaultValue: { color: 'lav4', title: '', body: '', kluddType: 'kludd-4', kluddWidth: 70, kluddColor: 'lav3', illustration: 'hearts' },
          subFields: [
            { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'title', type: 'text' },
            { name: 'body', type: 'longText' },
            { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
            { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'kluddWidth', type: 'number' },
            { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          ],
        },
        {
          name: 'usp4',
          friendlyName: 'USP 4',
          type: 'object',
          defaultValue: { color: 'lav4', title: '', body: '', kluddType: 'kludd-4', kluddWidth: 70, kluddColor: 'lav3', illustration: 'hand-4' },
          subFields: [
            { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'title', type: 'text' },
            { name: 'body', type: 'longText' },
            { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
            { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'kluddWidth', type: 'number' },
            { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          ],
        },
      ],
    }
  )

  Builder.registerComponent(withChildren(dynamic(() => import('components/builder_components/text_image/TextImage'))), {
    name: 'TextImage',
    friendlyName: 'Text & Image',
    models: ['page'],
    childRequirements: { component: 'SocialMedia', message: 'Children of this component must be Social media component.' },
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
      {
        name: 'fullbleedLayout',
        friendlyName: 'Use fullbleed layout',
        type: 'boolean',
        defaultValue: false,
      },
      { name: 'fullbleedImage', type: 'boolean', defaultValue: false },
      {
        name: 'mirroredLayout',
        type: 'boolean',
        helperText: 'Toggle this if you want the image to appear on the left side and the text on the right side',
        defaultValue: false,
      },
      {
        name: 'verticalLayout',
        type: 'boolean',
        helperText: 'Toggle this if you want the image to appear above the text',
        defaultValue: false,
        showIf: `options.get('fullbleedImage') === false`,
      },
      { name: 'subheadingCheck', type: 'boolean', defaultValue: false },
      { name: 'subheading', type: 'text', showIf: `options.get('subheadingCheck') === true` },
      { name: 'heading', type: 'text' },
      { name: 'headingPosition', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'left' },
      {
        name: 'ingress',
        type: 'longText',
        helperText: 'Short text below the heading, will be displayed in slighty bigger font size than body text',
      },
      { name: 'text', type: 'richText' },
      { name: 'textPosition', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'left' },

      { name: 'addList', helperText: 'Toggle this if you want to add a list item to your text section', type: 'boolean', defaultValue: false },
      {
        name: 'list',
        type: 'object',
        subFields: [
          { name: 'heading', type: 'text', defaultValue: 'List heading' },
          { name: 'startIndex', type: 'number', defaultValue: 1 },
          { name: 'dotColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'ljung2' },
          {
            name: 'listItem',
            type: 'list',
            subFields: [
              { name: 'subheading', type: 'text', defaultValue: 'Subheading' },
              { name: 'text', type: 'richText' },
            ],
          },
        ],
        defaultValue: { heading: 'List heading', startIndex: 1, dotColor: 'ljung2' },
        showIf: `options.get('addList') === true`,
      },
      { name: 'invertedTextColor', type: 'boolean', defaultValue: false },
      { name: 'addButton', type: 'boolean', defaultValue: false },
      {
        name: 'button',
        type: 'object',
        showIf: `options.get('addButton') === true`,
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'Create a photo book' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'createLink', friendlyName: 'Use "start create" button instead?', type: 'boolean', defaultValue: false },
          { name: 'productType', type: 'string', enum: [...PRODUCT_TYPES], defaultValue: 'book', showIf: `options.get('createLink') === true` },
          { name: 'link', type: 'string', defaultValue: '/photobooks', showIf: `options.get('createLink') === false` },
          {
            name: 'target',
            friendlyName: 'Open link in new window',
            type: 'boolean',
            defaultValue: false,
            showIf: `options.get('createLink') === false`,
          },
          { name: 'size', type: 'string', enum: ['s', 'm', 'l'], defaultValue: 'm' },
          { name: 'buttonPosition', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'left' },
        ],
        defaultValue: { text: 'Create a photo book', type: 'secondary', link: '/photobooks', size: 'm', buttonPosition: 'left' },
      },
      { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES, showIf: `options.get('slideshow') === false` },
      {
        name: 'altText',
        helperText: 'REQUIRED for accessibility, the more detailed info of the image, the better.',
        type: 'text',
        required: true,
        showIf: `options.get('slideshow') === false`,
      },
      {
        name: 'slideshow',
        friendlyName: 'Use multiple images',
        helperText: 'Toggle this if you want multiple images to be shown in a slideshow',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'slides',
        type: 'list',
        subFields: [{ name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES }, ...IMAGE_ALT_TEXT_INPUT],
        showIf: `options.get('slideshow') === true`,
      },
      {
        name: 'selectPaginator',
        type: 'object',
        subFields: [
          { name: 'color', type: 'string', enum: ['light', 'dark'], defaultValue: 'dark' },
          { name: 'position', type: 'string', enum: ['floating', 'bottom'], defaultValue: 'floating' },
        ],
        defaultValue: [{ color: 'dark', position: 'floating' }],
        showIf: `options.get('slideshow') === true`,
      },
    ],
  })

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/text_video/TextVideo')),
    {
      name: 'TextVideo',
      friendlyName: 'Text & Video',
      models: ['page'],
      inputs: [
        ...LAYOUT_INPUTS,
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
        {
          name: 'fullbleedLayout',
          friendlyName: 'Use fullbleed layout',
          type: 'boolean',
          defaultValue: true,
        },
        { name: 'fullbleedVideo', type: 'boolean', defaultValue: true },
        {
          name: 'mirroredLayout',
          type: 'boolean',
          helperText: 'Toggle this if you want the video to appear on the left side and the text on the right side',
          defaultValue: false,
        },
        {
          name: 'verticalLayout',
          type: 'boolean',
          helperText: 'Toggle this if you want the video to appear above the text',
          defaultValue: false,
          showIf: `options.get('fullbleedVideo') === false`,
        },
        { name: 'heading', type: 'text' },
        { name: 'headingPosition', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'left' },
        {
          name: 'ingress',
          type: 'longText',
          helperText: 'Short text below the heading, will be displayed in slighty bigger font size than body text',
        },
        { name: 'text', type: 'richText' },
        { name: 'textPosition', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'left' },
        { name: 'invertedTextColor', type: 'boolean', defaultValue: false },
        {
          name: 'video',
          type: 'object',
          subFields: [
            { name: 'file', type: 'file', allowedFileTypes: ALLOWED_VIDEO_FILE_TYPES },
            { name: 'posterImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
            { name: 'loop', type: 'boolean' },
            { name: 'muted', type: 'boolean' },
            { name: 'fileType', type: 'string', enum: ['mp4', 'mov', 'webm'], defaultValue: 'mp4' },
          ],
        },
        { name: 'addList', helperText: 'Toggle this if you want to add a list item to your text section', type: 'boolean', defaultValue: false },
        {
          name: 'list',
          type: 'object',
          subFields: [
            { name: 'heading', type: 'text', defaultValue: 'List heading' },
            { name: 'startIndex', type: 'number', defaultValue: 1 },
            { name: 'dotColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'ljung2' },
            {
              name: 'listItem',
              type: 'list',
              subFields: [
                { name: 'subheading', type: 'text', defaultValue: 'Subheading' },
                { name: 'text', type: 'richText' },
              ],
            },
          ],
          defaultValue: { heading: 'List heading', startIndex: 1, dotColor: 'ljung2' },
          showIf: `options.get('addList') === true`,
        },
        { name: 'addButton', type: 'boolean', defaultValue: false },
        {
          name: 'button',
          type: 'object',
          showIf: `options.get('addButton') === true`,
          subFields: [
            { name: 'text', type: 'text', defaultValue: 'Create a photo book' },
            { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
            { name: 'createLink', friendlyName: 'Use "start create" button instead?', type: 'boolean', defaultValue: false },
            { name: 'productType', type: 'string', enum: [...PRODUCT_TYPES], defaultValue: 'book', showIf: `options.get('createLink') === true` },
            { name: 'link', type: 'string', defaultValue: '/photobooks', showIf: `options.get('createLink') === false` },
            {
              name: 'target',
              friendlyName: 'Open link in new window',
              type: 'boolean',
              defaultValue: false,
              showIf: `options.get('createLink') === false`,
            },
            { name: 'size', type: 'string', enum: ['s', 'm', 'l'], defaultValue: 'm' },
          ],
          defaultValue: { text: 'Create a photo book', type: 'secondary', link: '/photobooks', size: 'm' },
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/heading/Heading')),
    {
      name: 'Heading',
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        {
          name: 'modelType',
          helperText: 'Choose here if this is a heading for a blog article or page',
          type: 'string',
          enum: ['blog', 'pages'],
          defaultValue: 'blog',
        },
        { name: 'text', type: 'text', helperText: 'This is where you write your heading' },
        { name: 'blogHeadingPosition', friendlyName: 'Text align', type: 'string', enum: ['left', 'center', 'right'] },
        { name: 'as', friendlyName: 'As tag', type: 'string', enum: ['span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5'] },
        { name: 'fontWeight', type: 'string', enum: [...Object.keys(themeVars.fontWeights)], defaultValue: 'bold' },
        { name: 'fontSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'xxl' },
        {
          name: 'anchorRef',
          friendlyName: 'Use as reference?',
          helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
          type: 'boolean',
          defaultValue: false,
          showIf: `model === 'blog-article'`,
        },
        {
          name: 'anchorRefName',
          friendlyName: 'Anchor Reference',
          type: 'string',
          showIf: `options.get('anchorRef') === true`,
          regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
        },
        {
          name: 'anchorLink',
          friendlyName: 'Use as anchor link?',
          helperText: 'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
          type: 'boolean',
          defaultValue: false,
          showIf: `model === 'blog-article'`,
        },
        {
          name: 'anchorLinkName',
          friendlyName: 'Anchor Link',
          type: 'string',
          showIf: `options.get('anchorLink') === true`,
          regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/text_component/TextComponent')),
    {
      name: 'AwesomeParagraphs',
      friendlyName: 'Text',
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        {
          name: 'modelType',
          helperText: 'Choose here if this is a text for a blog article or page',
          type: 'string',
          enum: ['blog', 'pages'],
          defaultValue: 'blog',
        },
        { name: 'dualColumns', type: 'boolean', defaultValue: false },
        {
          name: 'isPreamble',
          friendlyName: 'Display as ingress',
          helperText: 'This option makes the text bigger and with more line height',
          type: 'boolean',
          defaultValue: false,
        },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
        { name: 'headline', type: 'text', helperText: 'This is where you write your headline' },
        { name: 'headlineVariant', friendlyName: 'Type of headline', type: 'string', enum: ['heading', 'subheading'], defaultValue: 'heading' },
        { name: 'headlinePosition', type: 'string', enum: ['left', 'center', 'right'] },
        {
          name: 'anchorRef',
          friendlyName: 'Use as reference?',
          helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'anchorRefName',
          friendlyName: 'Anchor Reference',
          type: 'string',
          showIf: `options.get('anchorRef') === true`,
          regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
        },
        {
          name: 'anchorLink',
          friendlyName: 'Use as anchor link?',
          helperText: 'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'anchorLinkName',
          friendlyName: 'Anchor Link',
          type: 'string',
          showIf: `options.get('anchorLink') === true`,
          regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
        },
        { name: 'paragraphContent', type: 'richText' },
        { name: 'bodyTextAlign', friendlyName: 'Text align', type: 'string', enum: ['left', 'center', 'right'] },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/quote/Quote')),
    {
      name: 'Quote',
      friendlyName: 'Quote',
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        {
          name: 'modelType',
          helperText: 'Choose here if this quote is for a blog article or page',
          type: 'string',
          enum: ['blog', 'pages'],
          defaultValue: 'pages',
        },
        { name: 'quote', type: 'longText', defaultValue: 'Did we always have that much fun? Did we never fight? Was there always a party?' },
        { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
        {
          name: 'fontSize',
          type: 'string',
          enum: [...Object.keys(ouTheme.fontSizes)],
          defaultValue: 'xl',
        },
        { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'left' },
        {
          name: 'lineHeight',
          type: 'string',
          enum: [...Object.keys(ouTheme.lineHeights)],
          defaultValue: 'l',
          showIf: `options.get('modelType') === 'blog'`,
        },
        {
          name: 'illustration',
          friendlyName: 'Image/Illustration',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('modelType') === 'blog'`,
        },
        {
          name: 'altText',
          helperText:
            'REQUIRED for accessibility, the more detailed info of the image, the better. Leave empty if you want to use the default "Photo books" alt text.',
          type: 'text',
          showIf: `options.get('modelType') === 'blog'`,
        },
        {
          name: 'illustrationPosition',
          type: 'string',
          enum: ['left', 'right'],
          defaultValue: 'left',
          showIf: `options.get('modelType') === 'blog'`,
        },
        {
          name: 'fontWeight',
          type: 'string',
          enum: [...Object.keys(themeVars.fontWeights)],
          defaultValue: 'bold',
          showIf: `options.get('modelType') === 'pages'`,
        },
        {
          name: 'backgroundColor',
          type: 'string',
          enum: [...Object.keys(OU_COLORS)],
          defaultValue: 'mossa4',
          showIf: `options.get('modelType') === 'pages'`,
        },
        { name: 'signature', type: 'text', showIf: `options.get('modelType') === 'pages'` },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/blog_components/list_blog/ListBlog')),
    {
      name: 'Paragraphwithlist',
      friendlyName: 'List',
      models: ['blog-article'],
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'dotColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'blåbär2' },
        { name: 'startIndex', type: 'number', defaultValue: 1 },
        {
          name: 'list',
          type: 'list',
          subFields: [
            { name: 'title', type: 'text' },
            {
              name: 'anchorRef',
              friendlyName: 'Use as reference?',
              helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
              type: 'boolean',
              defaultValue: false,
              showIf: `model === 'blog-article'`,
            },
            {
              name: 'anchorRefName',
              friendlyName: 'Anchor Reference',
              type: 'string',
              showIf: `options.get('anchorRef') === true`,
              regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
            },
            {
              name: 'anchorLink',
              friendlyName: 'Use as anchor link?',
              helperText:
                'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
              type: 'boolean',
              defaultValue: false,
              showIf: `model === 'blog-article'`,
            },
            {
              name: 'anchorLinkName',
              friendlyName: 'Anchor Link',
              type: 'string',
              showIf: `options.get('anchorLink') === true`,
              regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
            },
            { name: 'paragraph', type: 'richText' },
          ],
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/images_component/ImagesComponent')),
    {
      name: 'AwesomeImageComponent',
      friendlyName: 'Images',
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'fullBleed', friendlyName: 'Fullbleed images', type: 'boolean', defaultValue: false },
        { name: 'showGutter', friendlyName: 'Show space between images', type: 'boolean', defaultValue: true },
        {
          name: 'globalBorderRadius',
          friendlyName: 'Border radius',
          helperText: 'This will give your image rounded corners, higher number equals more rounded corners',
          type: 'number',
          defaultValue: 8,
        },
        {
          name: 'images',
          friendlyName: 'Images',
          type: 'list',
          subFields: [
            { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
            { name: 'objectFit', type: 'string', enum: ['contain', 'cover'], defaultValue: 'cover' },
            {
              name: 'altText',
              helperText:
                'REQUIRED for accessibility, the more detailed info of the image, the better. Leave empty if you want to use the default "Photo books" alt text.',
              type: 'text',
            },
            { name: 'caption', type: 'string' },
            { name: 'fractionInGrid', type: 'string', enum: ['1fr', '2fr', '3fr'], defaultValue: '1fr' },
            { name: 'maxHeight', type: 'number', defaultValue: 0 },
          ],
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/image_slider_component/ImageSliderComponent')),
    {
      name: 'SliderSection',
      friendlyName: 'Image Slider',
      models: ['page'],
      inputs: [
        ...LAYOUT_INPUTS,
        {
          name: 'marginBottom',
          friendlyName: 'Bottom margin',
          type: 'string',
          enum: [...Object.keys(themeVars.spaces)],
          defaultValue: 'xxxl',
          hideFromUI: true,
        },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
        { name: 'heading', type: 'text', helperText: 'Can be left empty' },
        { name: 'body', type: 'longText', helperText: 'Can be left empty' },
        { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'center' },
        {
          name: 'slides',
          type: 'list',
          subFields: [{ name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES }, ...IMAGE_ALT_TEXT_INPUT],
        },
        { name: 'imageGap', type: 'boolean', defaultValue: false },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/prices/Prices')),
    {
      name: 'PricesSection',
      friendlyName: 'Prices',
      models: ['page'],
      inputs: [
        { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
        { name: 'productType', type: 'string', enum: ['book', 'print'], required: true, defaultValue: 'book' },
        { name: 'title', type: 'string', defaultValue: '' },
        { name: 'subtitle', type: 'string', defaultValue: '' },
        { name: 'customCoverImage', friendlyName: 'Add custom cover images', type: 'boolean', defaultValue: false },
        {
          name: 'book27x27cmHardCoverImage',
          friendlyName: 'Image (hardcover 27x27)',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
        },
        {
          name: 'book20x20cmHardCoverImage',
          friendlyName: 'Image (hardcover 20x20)',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
        },
        {
          name: 'book20x20cmSoftCoverImage',
          friendlyName: 'Image (softcover)',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
        },
        {
          name: 'semiGlossPaperImage',
          friendlyName: 'Image (semi-gloss paper)',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `(options.get('customCoverImage') === true && options.get('productType') === 'print')`,
        },
        {
          name: 'mattePaperImage',
          friendlyName: 'Image (matte paper)',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('customCoverImage') === true && options.get('productType') === 'print'`,
        },
        { name: 'cardColors', friendlyName: 'Change card colors', type: 'boolean', defaultValue: false },
        {
          name: 'largeHardCoverCardColor',
          friendlyName: 'Card color (hardcover 27x27)',
          type: 'string',
          enum: [...Object.keys(OU_COLORS)],
          showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
        },
        {
          name: 'mediumHardCoverCardColor',
          friendlyName: 'Card color (hardcover 20x20)',
          type: 'string',
          enum: [...Object.keys(OU_COLORS)],
          showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
        },
        {
          name: 'softCoverCardColor',
          friendlyName: 'Card color (softcover)',
          type: 'string',
          enum: [...Object.keys(OU_COLORS)],
          showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
        },
        {
          name: 'semiGlossCardColor',
          friendlyName: 'Card color (semi-gloss paper)',
          type: 'string',
          enum: [...Object.keys(OU_COLORS)],
          showIf: `options.get('cardColors') === true && options.get('productType') === 'print'`,
        },
        {
          name: 'matteCardColor',
          friendlyName: 'Card color (matte paper)',
          type: 'string',
          enum: [...Object.keys(OU_COLORS)],
          showIf: `options.get('cardColors') === true && options.get('productType') === 'print'`,
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/covers/Covers')),
    {
      name: 'CoversSection',
      friendlyName: 'Covers',
      models: ['page'],
      inputs: [
        { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
        { name: 'title', type: 'text', defaultValue: '' },
        { name: 'buttonText', type: 'text', defaultValue: '' },
        { name: 'hardcoverUsp1', type: 'text', defaultValue: '' },
        { name: 'hardcoverUsp2', type: 'text', defaultValue: '' },
        { name: 'hardcoverUsp3', type: 'text', defaultValue: '' },
        { name: 'softcoverUsp1', type: 'text', defaultValue: '' },
        { name: 'softcoverUsp2', type: 'text', defaultValue: '' },
        { name: 'softcoverUsp3', type: 'text', defaultValue: '' },
        { name: 'customCoverImage', friendlyName: 'Add custom cover images', type: 'boolean', defaultValue: false },
        {
          name: 'hardCoverImage',
          friendlyName: 'Hard Cover image',
          helperText: 'Add custom cover image, leave empty for default image',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('customCoverImage') === true`,
        },
        {
          name: 'softCoverImage',
          friendlyName: 'Soft Cover image',
          helperText: 'Add custom cover image, leave empty for default image',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('customCoverImage') === true`,
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/reviews/Reviews')),
    {
      name: 'ReviewsSection',
      friendlyName: 'Reviews',
      models: ['page'],
      inputs: [
        { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'lav4' },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('../components/builder_components/button_component/ButtonComponent')),
    {
      name: 'ButtonComponent',
      friendlyName: 'Button',
      inputs: [
        ...LAYOUT_INPUTS,
        { name: 'variant', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'primary' },
        { name: 'size', type: 'string', enum: ['s', 'm', 'l'], defaultValue: 'l' },
        { name: 'link', type: 'string', required: true },
        { name: 'invertedColor', type: 'boolean', defaultValue: false },
        { name: 'buttonText', type: 'text' },
        { name: 'buttonPosition', type: 'string', enum: ['left', 'center', 'right', 'none'] },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/contact_forms/ContactForms')),
    {
      name: 'ContactForms',
      friendlyName: 'Contact forms',
      models: ['page'],
      inputs: [
        { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
        {
          name: 'extraMarginBottom',
          friendlyName: 'Add extra space in bottom',
          helperText: 'Did the margin bottom not give enough space? Use this to add more',
          type: 'boolean',
          defaultValue: false,
        },
        {
          name: 'extraMarginBottomSize',
          type: 'string',
          enum: [...Object.keys(themeVars.spaces)],
          defaultValue: 'xl',
          showIf: `options.get('extraMarginBottom') === true`,
        },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], required: true, defaultValue: 'ljung4' },
        { name: 'useTitle', friendlyName: 'Add title', type: 'boolean', defaultValue: true },
        { name: 'title', type: 'text', showIf: `options.get('useTitle') === true` },
        { name: 'formType', type: 'string', enum: ['collaborate', 'support', 'feedback'] },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/facts/Facts')),
    {
      name: 'FunFacts',
      friendlyName: 'Facts',
      models: ['page'],
      inputs: [
        { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'hjortron4' },
        { name: 'fullbleedLayout', type: 'boolean', defaultValue: false },
        {
          name: 'funFact1',
          friendlyName: 'Fact 1',
          type: 'object',
          required: true,
          defaultValue: { illustration: 'chat', kluddColor: 'blåbär3', text: '' },
          subFields: [
            { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
            { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
            { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'kluddWidth', type: 'number' },
            { name: 'text', type: 'longText' },
          ],
        },
        {
          name: 'funFact2',
          friendlyName: 'Fact 2',
          type: 'object',
          required: true,
          defaultValue: { illustration: 'peace', kluddColor: 'kantarell2', text: '' },
          subFields: [
            { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
            { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
            { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'kluddWidth', type: 'number' },
            { name: 'text', type: 'longText' },
          ],
        },
        {
          name: 'funFact3',
          friendlyName: 'Fact 3',
          type: 'object',
          required: true,
          defaultValue: { illustration: 'stars-1', kluddColor: 'ljung2', text: '' },
          subFields: [
            { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
            { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
            { name: 'kluddColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
            { name: 'kluddWidth', type: 'number' },
            { name: 'text', type: 'longText' },
          ],
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/pre_footer/PreFooter')),
    {
      name: 'PreFooter',
      friendlyName: 'Pre-footer',
      models: ['page'],
      inputs: [
        { name: 'type', type: 'string', enum: ['product', 'generic'], defaultValue: 'generic' },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'hjortron2' },
        {
          name: 'illustration',
          type: 'string',
          enum: [...illustrationTypes],
          defaultValue: 'diamond',
          showIf: `options.get('useImage') === false && options.get('type') !== 'product'`,
        },
        { name: 'useImage', friendlyName: 'Use image instead?', type: 'boolean', defaultValue: false, showIf: `options.get('type') !== 'product'` },
        {
          name: 'image',
          type: 'file',
          allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES,
          showIf: `options.get('useImage') === true && options.get('type') !== 'product'`,
        },
        {
          name: 'altText',
          helperText: 'REQUIRED for accessibility, the more detailed info of the image, the better.',
          type: 'text',
          required: true,
          showIf: `options.get('useImage') === true && options.get('type') !== 'product'`,
        },
        { name: 'illustrationHeight', friendlyName: 'Illustration height Desktop', type: 'number', showIf: `options.get('type') !== 'product'` },
        { name: 'illustrationHeightMobile', type: 'number', showIf: `options.get('type') !== 'product'` },
        { name: 'headline', type: 'text', defaultValue: 'This is the headline', showIf: `options.get('type') !== 'product'` },
        { name: 'text', type: 'longText', showIf: `options.get('type') !== 'product'` },
        {
          name: 'button',
          type: 'object',
          defaultValue: { text: 'This is the button text', type: 'secondary', link: '/about-us/work-with-us', size: 'm' },
          showIf: `options.get('type') !== 'product'`,
          subFields: [
            { name: 'text', type: 'text', defaultValue: 'This is the button text' },
            { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
            { name: 'link', type: 'string', defaultValue: '/photobooks' },
            { name: 'target', friendlyName: 'Open link in new window', type: 'boolean', defaultValue: false },
            { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
          ],
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/puff/Puff')),
    {
      name: 'TipsSection',
      models: ['page'],
      friendlyName: 'Puff (images)',
      inputs: [
        ...LAYOUT_INPUTS,
        { name: 'circleText', type: 'text', defaultValue: '' },
        { name: 'circleColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'blåbär2' },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'transparent' },
        { name: 'title', type: 'longText', defaultValue: '' },
        { name: 'description', type: 'longText', defaultValue: '' },
        { name: 'linkHref', type: 'url', defaultValue: '/photobooks' },
        { name: 'linkText', type: 'text', defaultValue: '' },
        { name: 'smallImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
        {
          name: 'smallImageAltText',
          friendlyName: 'altText',
          type: 'text',
          required: true,
          helperText: 'REQUIRED for accessibility, the more detailed info of the image, the better.',
        },
        { name: 'coverImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
        {
          name: 'coverImageAltText',
          friendlyName: 'altText',
          type: 'text',
          required: true,
          helperText: 'REQUIRED for accessibility, the more detailed info of the image, the better.',
        },
        { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        { name: 'illustrationBoxColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'blåbär3' },
        { name: 'illustrationBoxText', type: 'longText', defaultValue: '' },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/countdown/Countdown')),
    {
      name: 'Countdown',
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'fullbleed', friendlyName: 'Use fullbleed layout', type: 'boolean', defaultValue: true },
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
        { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
        { name: 'fontSize', type: 'string', enum: [...Object.keys(ouTheme.fontSizes)] },
        { name: 'invertedTextColor', helperText: 'Toggle this if you want white text color', type: 'boolean' },
        { name: 'contentAlignment', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'center' },
        { name: 'targetDate', type: 'date', helperText: 'Pick start date for event', required: true },
        { name: 'targetText', type: 'text', helperText: 'This text is shown under the countdown' },
        { name: 'addEndDate', type: 'boolean', defaultValue: false },
        { name: 'eventEndDate', helperText: 'Pick end date for event', type: 'date', showIf: `options.get('addEndDate') === true` },
        { name: 'eventEndText', type: 'text', helperText: 'Text to under the countdown', showIf: `options.get('addEndDate') === true` },
        { name: 'useTextGradient', helperText: 'Adds a fading gradient to the text (not the countdown)', type: 'boolean', defaultValue: false },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/social_media/SocialMedia')),
    {
      name: 'SocialMedia',
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        {
          name: 'direction',
          helperText: 'Direction in which the icons are displayed',
          type: 'string',
          enum: ['vertical', 'horizontal'],
          defaultValue: 'horizontal',
        },
        { name: 'iconSize', type: 'string', enum: ['s', 'm', 'l'], defaultValue: 'm' },
        { name: 'iconSpacing', helperText: 'Space between each icon', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xs' },
        { name: 'iconColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'malm0' },
        {
          name: 'socialLink',
          friendlyName: 'Links',
          helperText: 'Add your social media links here',
          type: 'list',
          subFields: [
            { name: 'label', type: 'text' },
            { name: 'link', type: 'url' },
            { name: 'icon', type: 'string', enum: [...socialMediaIconTypes] },
          ],
        },
      ],
    }
  )

  Builder.registerComponent(
    dynamic(() => import('components/builder_components/illustration/Illustration')),
    {
      name: 'IllustrationSection',
      friendlyName: 'Illustration',
      models: ['page'],
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'useImage', friendlyName: 'Use image instead', type: 'boolean', defaultValue: false },
        {
          name: 'image',
          type: 'object',
          showIf: `options.get('useImage') === true`,
          subFields: [
            { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
            ...IMAGE_ALT_TEXT_INPUT,
            { name: 'width', type: 'number', defaultValue: 100 },
            { name: 'height', type: 'number', defaultValue: 100 },
          ],
        },
        {
          name: 'illustration',
          type: 'string',
          enum: [...illustrationTypes, ...arrowIllustrationTypes, ...kluddIllustrationTypes],
          defaultValue: 'arrow-5',
          showIf: `options.get('useImage') === false`,
        },
        { name: 'illustrationColor', friendlyName: 'Color', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'malm0' },
        { name: 'illustrationPosition', friendlyName: 'Position', type: 'string', enum: ['left', 'right', 'center'], defaultValue: 'left' },
        { name: 'illustrationSize', friendlyName: 'Size', type: 'string', enum: ['s', 'm', 'l'], defaultValue: 'm' },
        {
          name: 'arrowOptions',
          type: 'object',
          subFields: [
            { name: 'arrowDirection', type: 'string', enum: ['up', 'down'], defaultValue: 'down' },
            {
              name: 'arrowRotation',
              friendlyName: 'Rotate arrow',
              helperText: 'Add the degrees you want the illustration to rotate by',
              type: 'range',
              defaultValue: 0,
              min: 0,
              max: 100,
            },
          ],
          showIf: `options.get('illustration').includes('arrow')`,
        },
      ],
    }
  )

  Builder.registerComponent(withChildren(dynamic(() => import('../atomic/components/atoms/container/Container'))), {
    name: 'OUContainer',
    models: ['page'],
    friendlyName: 'Container (Utopia)',
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'background', type: 'string', enum: [...Object.keys(OU_COLORS)] },
      { name: 'customWidth', friendlyName: 'Max width', type: 'number' },
    ],
  })

  Builder.registerComponent(withChildren(dynamic(() => import('../atomic/components/atoms/box/Box'))), {
    name: 'OUBox',
    models: ['page'],
    friendlyName: 'Box (Utopia)',
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'background', type: 'string', enum: [...Object.keys(OU_COLORS)] },
      { name: 'display', type: 'string', enum: ['block', 'flex'], defaultValue: 'flex' },
      { name: 'flexDirection', type: 'string', enum: ['row', 'column', 'row-reverse', 'column-reverse'], defaultValue: 'row' },
      { name: 'gap', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'm' },
      { name: 'maxWidth', type: 'number' },
    ],
  })

  Builder.registerComponent(withChildren(dynamic(() => import('../atomic/components/molecules/carousel/Carousel'))), {
    name: 'Carousel',
    models: ['page'],
    inputs: [
      ...LAYOUT_INPUTS,
      { name: 'paginatorPosition', type: 'string', enum: ['floating', 'bottom'], defaultValue: 'bottom' },
      { name: 'paginatorColor', type: 'string', enum: ['dark', 'light'], defaultValue: 'dark' },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)] },
    ],
  })

  /*
   * =======================================
   * HIDDEN COMPONENTS
   * reason explained under respective
   * component
   * =======================================
   */

  // Only used in FAQ page
  Builder.registerComponent(
    dynamic(() => import('components/kundo/Kundo')),
    {
      name: 'Kundo',
      friendlyName: 'Kundo',
      models: ['page'],
      hideFromInsertMenu: true,
      inputs: [...MARGIN_PADDING_INPUTS],
    }
  )

  // Only used in Work with us page
  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/jobs/Jobs')),
    {
      name: 'JobOptions',
      friendlyName: 'Jobs',
      models: ['page'],
      hideFromInsertMenu: true,
      inputs: [
        { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
        { name: 'title', type: 'text' },
        { name: 'deadline', type: 'text' },
      ],
    }
  )

  // Only used in Help pages
  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/payments/Payments')),
    {
      name: 'PaymentMethods',
      friendlyName: 'Payments',
      hideFromInsertMenu: true,
      models: ['page'],
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'white' },
        { name: 'borderRadius', type: 'string', enum: [...Object.keys(themeVars.borderRadiuses)], defaultValue: 'm' },
        { name: 'heading', type: 'text' },
        {
          name: 'payOptions',
          type: 'list',
          subFields: [
            { name: 'title', type: 'text' },
            { name: 'description', type: 'longText' },
          ],
        },
      ],
    }
  )

  // Only used in Help pages
  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/delivery/Delivery')),
    {
      name: 'DeliverySection',
      friendlyName: 'Delivery',
      hideFromInsertMenu: true,
      models: ['page'],
      inputs: [...MARGIN_PADDING_INPUTS, { name: 'heading', type: 'text' }],
    }
  )

  // Only used in Mom's story campaign page
  Builder.registerComponent(
    dynamic(() => import('components/builder_components/old_components/momsstory_components/contract_section/ContractSection')),
    {
      name: 'ContractSection',
      friendlyName: 'Contract section',
      models: ['page'],
      hideFromInsertMenu: true,
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'backgroundImage', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
        { name: 'image', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
        {
          name: 'altText',
          helperText: 'The more detailed info of the image, the better. Alt text is REQUIRED for accessibility',
          type: 'text',
          defaultValue: 'Image',
        },
        { name: 'heading', type: 'text', defaultValue: 'This is the heading' },
        { name: 'addHeadingLink', friendlyName: 'Add anchor link to heading', type: 'boolean', defaultValue: false },
        {
          name: 'headingLink',
          friendlyName: 'Anchor link',
          type: 'text',
          showIf: `options.get('addHeadingLink') === true`,
          regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
        },
        { name: 'intro', type: 'richText' },
        {
          name: 'button',
          type: 'object',
          defaultValue: { text: 'This is the button text', type: 'secondary', link: '', size: 'l' },
          subFields: [
            { name: 'text', type: 'text', defaultValue: 'This is the button text' },
            { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
            { name: 'link', type: 'string', defaultValue: '/create' },
            { name: 'target', friendlyName: 'Open link in new window', type: 'boolean', defaultValue: false },
            { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
          ],
        },
        {
          name: 'handwrittenText',
          friendlyName: 'Handwritten text',
          type: 'object',
          defaultValue: { text: 'This is the handwritten text', fontFamily: 'accent', fontSize: 'xl', lineHeight: 'xs' },
          subFields: [
            { name: 'text', type: 'longText', defaultValue: 'This is the quote' },
            { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
            { name: 'fontSize', type: 'string', enum: [...Object.keys(ouTheme.fontSizes)], defaultValue: 'xl' },
            { name: 'lineHeight', type: 'string', enum: [...Object.keys(ouTheme.lineHeights)], defaultValue: 'xs' },
          ],
        },
      ],
    }
  )

  // Only used in Mom's story campaign page
  Builder.registerComponent(
    dynamic(() => import('components/builder_components/old_components/momsstory_components/statistics_container/StatisticsContainer')),
    {
      name: 'StatisticsContainer',
      models: ['page'],
      hideFromInsertMenu: true,
      inputs: [
        {
          name: 'statisticsSlides',
          type: 'list',
          subFields: [
            { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
            {
              name: 'altText',
              helperText: 'The more detailed info of the image, the better. Alt text is REQUIRED for accessibility',
              type: 'text',
              defaultValue: 'Image',
            },
            { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(OU_COLORS)], defaultValue: 'kantarell3' },
            { name: 'title', type: 'text' },
            { name: 'text', type: 'longText' },
          ],
        },
        { name: 'heading', type: 'text' },
        { name: 'intro', type: 'longText' },
        { name: 'source', type: 'longText' },
      ],
    }
  )

  // Only used in Mom's story campaign page
  Builder.registerComponent(
    dynamic(() => import('components/builder_components/old_components/momsstory_components/logos_component/LogosComponent')),
    {
      name: 'LogosComponent',
      models: ['page'],
      hideFromInsertMenu: true,
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'intro', type: 'longText' },
        {
          name: 'fontSettings',
          type: 'object',
          subFields: [
            { name: 'family', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
            { name: 'size', type: 'string', enum: [...Object.keys(ouTheme.fontSizes)], defaultValue: 'xl' },
            { name: 'weight', type: 'string', enum: [...Object.keys(ouTheme.fontWeights)], defaultValue: 'normal' },
            { name: 'align', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'center' },
          ],
        },
        {
          name: 'logos',
          type: 'list',
          subFields: [
            { name: 'src', type: 'file', allowedFileTypes: ALLOWED_IMAGE_FILE_TYPES },
            {
              name: 'altText',
              helperText: 'The more detailed info of the image, the better. Alt text is REQUIRED for accessibility',
              type: 'text',
              defaultValue: 'Image',
            },
            { name: 'maxHeight', type: 'number' },
            { name: 'link', type: 'text' },
          ],
        },
      ],
    }
  )

  // Only used in Christmas Delivery page
  Builder.registerComponent(
    dynamic(() => import('components/builder_components/page_components/christmas_delivery/ChristmasDelivery')),
    {
      name: 'ChristmasDelivery',
      models: ['page'],
      hideFromInsertMenu: true, // only used in Christmas Delivery page
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'color', helperText: 'Background color on delivery date list', type: 'string', enum: [...Object.keys(OU_COLORS)] },
      ],
    }
  )

  // Not in use, we should use ButtonComponent instead in Builder
  // because this component should be as generic as possible
  Builder.registerComponent(
    dynamic(() => import('../atomic/components/atoms/button/Button')),
    {
      name: 'Button',
      hideFromInsertMenu: true,
      inputs: [
        ...LAYOUT_INPUTS,
        { name: 'variant', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'primary' },
        { name: 'children', friendlyName: 'Label', helperText: 'This is where you write the text in your button', type: 'string' },
        { name: 'blogButtonPosition', friendlyName: 'Button position', type: 'string', enum: ['left', 'center', 'right', 'none'] },
      ],
    }
  )

  // Not in use anymore
  Builder.registerComponent(
    withChildren(
      dynamic(() => import('components/builder_components/old_components/momsstory_components/customizable_container/CustomizableContainer'))
    ),
    {
      name: 'CustomizableContainer',
      models: ['page'],
      hideFromInsertMenu: true,
      inputs: [
        ...MARGIN_PADDING_INPUTS,
        { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouTheme.colors)], defaultValue: 'blåbär3' },
        { name: 'secondColor', type: 'string', enum: [...Object.keys(ouTheme.colors)] },
        { name: 'addWaves', type: 'boolean', defaultValue: true },
        { name: 'direction', type: 'string', enum: ['row', 'column', 'row-reverse', 'column-reverse'], defaultValue: 'row' },
        { name: 'space', friendlyName: 'Space between columns', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xl' },
      ],
      defaultChildren: [
        {
          '@type': '@builder.io/sdk:Element',
          component: { name: 'Text', options: { text: 'I am child text block!' } },
        },
      ],
    }
  )
}
