import React, { FC } from 'react'
import { container } from './Container.css'
import Box, { BoxProps } from '../box/Box'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type ContainerProps = {
  customWidth?: number
} & BoxProps &
  BuilderProps

const Container: FC<ContainerProps> = ({ children, className, marginX = 'auto', paddingX = 's-l', customWidth, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Box
      className={`${container[customWidth ? 'custom' : 'default']} ${className}`}
      paddingX={paddingX}
      marginX={marginX}
      style={customWidth ? { maxWidth: customWidth } : {}}
      {...cleanProps}
    >
      {children}
    </Box>
  )
}

export default Container
