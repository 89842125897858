/**
 * Stores the current URL's query parameters in sessionStorage, or in
 * cookies if sessionStorage isn't available.
 * This ensures that parameters (e.g., campaign tracking data) persist
 * across navigation within the session. The data is removed when the user
 * closes the browser tab or window, or if cookies are used, expires after 24 hours.
 *
 * This function should be called once when the app initializes to capture
 * the initial query parameters.
 *
 *  * @param {any} setCookie - setCookie function from react-cookie
 */
export const storePreservedParams = (setCookie) => {
  if (typeof window !== 'undefined') {
    const params = window.location.search.substring(1)
    if (params) {
      // Try using sessionStorage first
      try {
        if (window.sessionStorage) {
          // console.log('Storing preserved query params in sessionStorage:', params)
          sessionStorage.setItem('preservedQueryParams', params)
        }
      } catch (e) {
        // console.log('sessionStorage is unavailable or failed, falling back to cookies.')
        // If sessionStorage isn't available, fallback to cookies
        setCookie('preservedQueryParams', params, { path: '/', maxAge: 24 * 60 * 60 })
      }
    }
  }
}

/**
 * This function generates a new URL by appending query parameters from two sources:
 * 1. **Preserved query parameters** stored in `sessionStorage` (if available).
 * 2. **Current query parameters** from the `asPath` (i.e., the path the user is currently on).
 *
 * It ensures that the preserved parameters are only added to the target URL if they are not already present in the target's query string.
 * Additionally, it only modifies URLs that are on the same domain (i.e., containing 'onceupon.photo' or 'onceuponapp.onelink').
 *
 * If no query string exists in the target URL, it simply appends the parameters to the path.
 *
 * @param {string} href - The target URL to modify.
 * @param {string} asPath - The current URL (including query params) to extract parameters from.
 *
 * @returns {string} - The modified target URL with preserved and current query parameters.
 */
export const getPreservedUrl = (href: string, asPath: string, cookies) => {
  if (href === undefined || asPath === undefined) {
    return href
  }

  // Ignore links unless they point to onceupon.photo or onelink
  if (href.indexOf('onceupon.photo') === -1 && href.indexOf('onceuponapp.onelink') === -1) {
    return href
  }

  const [hrefPath, hrefQuery] = href.split('?')
  const hrefParams = new URLSearchParams(hrefQuery || '')
  const currentParams = new URLSearchParams(asPath.split('?')[1] || '')

  // Try reading preserved params from sessionStorage
  let preservedParams = null
  if (typeof window !== 'undefined') {
    try {
      // Check sessionStorage
      preservedParams = sessionStorage.getItem('preservedQueryParams')

      // If sessionStorage is not available, fall back to cookies
      if (!preservedParams) {
        preservedParams = cookies['preservedQueryParams'] // Retrieve preserved params from cookies
      }
    } catch (e) {
      // Handle errors (if sessionStorage is blocked or unavailable)
      // console.error('Error reading sessionStorage:', e)
    }
  }

  // If preserved params are found (either from sessionStorage or cookies)
  if (preservedParams) {
    const preservedSearchParams = new URLSearchParams(preservedParams)

    // Append preserved params, but only if they don't already exist in the href
    preservedSearchParams.forEach((value, key) => {
      if (!hrefParams.has(key)) {
        hrefParams.append(key, value)
      }
    })
  }

  // Append current URL params, but only if they don't already exist
  currentParams.forEach((value, key) => {
    if (!hrefParams.has(key)) {
      hrefParams.append(key, value)
    }
  })

  const newQueryString = hrefParams.toString()
  const finalHref = newQueryString ? `${hrefPath}?${newQueryString}` : hrefPath
  return finalHref
}
