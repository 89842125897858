import { useOuCookies } from 'components/cookiebanner/CookieBanner'
import { getUserCountry } from 'lib/firebase'
import { createContext, useContext, useEffect, useState, FC } from 'react'
import { storePreservedParams } from 'utils/preserve-query'

type CookieContextType = {
  cookieLocale: string
  setCookieLocale: (newLocale: string) => void
  cookieCountry: string
  setCookieCountry: (newCountry: string) => void
  consentNecessary: boolean
  consentAnalytical: boolean
  consentMarketing: boolean
  cookies: { [key: string]: string }
  setCustomCookies: (name: string, value: string) => void
}

const CookieContext = createContext<CookieContextType | null>(null)

export const CookieProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { cookies, setCustomCookies, consentNecessary, consentAnalytical, consentMarketing } = useOuCookies()
  const [cookieCountry, setCookieCountryState] = useState<string | null>(cookies['country'] || null)
  const [cookieLocale, setCookieLocaleState] = useState<string | null>(cookies['NEXT_LOCALE'] || null)
  const [isLoading, setIsLoading] = useState<boolean>(true) // Add a loading state

  // Set default values for cookies if they don't exist yet
  useEffect(() => {
    const setDefaultValues = async () => {
      // default values for country
      if (!cookies['country'] && !cookieCountry) {
        const defaultCountry = await getUserCountry() // Fetch the default country from the API
        const finalCountry = defaultCountry ?? 'GB'
        setCustomCookies('country', finalCountry) // Set the country cookie
        setCookieCountry(finalCountry) // Update state with the fetched default country
      }

      // default values for country
      if (!cookies['NEXT_LOCALE'] && !cookieLocale) {
        setCustomCookies('NEXT_LOCALE', 'en-gb') // Default locale if not set
        setCookieLocale('en-gb') // Update locale state
      }

      setIsLoading(false) // Set loading state to false once fetching is complete
    }

    setDefaultValues()
  }, [cookies])

  // Store preserved query params if needed
  useEffect(() => {
    storePreservedParams(setCustomCookies)
  }, [setCustomCookies])

  const setCookieLocale = (newLocale: string) => {
    if (newLocale === 'undefined') return
    setCustomCookies('NEXT_LOCALE', newLocale) // Update cookie
    setCookieLocaleState(newLocale) // Update state
  }

  const setCookieCountry = (newCountry: string) => {
    if (newCountry === 'undefined') return
    setCustomCookies('country', newCountry) // Update cookie
    setCookieCountryState(newCountry) // Update state
  }

  // Conditionally render based on the loading state. Will cause timing issues if not handled
  if (isLoading) {
    return null
  }

  return (
    <CookieContext.Provider
      value={{
        cookieLocale,
        setCookieLocale,
        cookieCountry,
        setCookieCountry,
        consentNecessary,
        consentAnalytical,
        consentMarketing,
        cookies,
        setCustomCookies,
      }}
    >
      {children}
    </CookieContext.Provider>
  )
}

export const useCookieContext = () => {
  const context = useContext(CookieContext)
  if (!context) {
    throw new Error('useCookieContext must be used within a CookieProvider')
  }
  return context
}
