import React, { AllHTMLAttributes, FC } from 'react'

import { Sprinkles, sprinkles } from '../../../styles/sprinkles.css'
import { parseSprinkleProps } from '../helpers/sprinkleHelper'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

export type BoxProps = Omit<AllHTMLAttributes<HTMLElement>, 'className' | 'content' | 'height' | 'translate' | 'color' | 'width' | 'cursor'> &
  Sprinkles &
  BuilderProps & {
    className?: string
    as?: 'div' | 'header' | 'footer' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
    children?: React.ReactNode
    maxWidth?: number
  }

const Box: FC<BoxProps> = ({ as: Tag = 'div', children, className = '', maxWidth, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const { sprinkleProps, otherProps } = parseSprinkleProps(cleanProps)
  return (
    <Tag className={`${className} ${sprinkles(sprinkleProps)}`} style={maxWidth ? { maxWidth: maxWidth } : undefined} {...otherProps}>
      {children}
    </Tag>
  )
}

export default Box
