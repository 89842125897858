import clsx from 'clsx'
import { useInView } from 'framer-motion'
import React, { FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react'

import Box, { BoxProps } from '../../atoms/box/Box'
import * as styles from './Carousel.css'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type CarouselProps = BoxProps & {
  items: ReactNode[]
  className?: string
  children?: React.ReactNode
  paginatorPosition?: 'floating' | 'bottom' | 'hidden'
  paginatorColor?: 'light' | 'dark'
  autoChangeInterval?: number // in milliseconds
} & BuilderProps

const useHorizontalDragToScroll = (): RefObject<HTMLDivElement> => {
  const containerRef = useRef<HTMLDivElement>(null)
  const startXRef = useRef(0)
  const scrollLeftRef = useRef(0)

  useEffect(() => {
    const container = containerRef.current
    if (!container) return

    const handleMouseDown = (event: MouseEvent): void => {
      event.preventDefault()
      startXRef.current = event.pageX - container.offsetLeft
      scrollLeftRef.current = container.scrollLeft
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    const handleMouseUp = (): void => {
      document.removeEventListener('mouseup', handleMouseUp)
      document.removeEventListener('mousemove', handleMouseMove)
    }

    const handleMouseMove = (event: MouseEvent): void => {
      event.preventDefault()
      const x = event.pageX - container.offsetLeft
      const walk = (x - startXRef.current) * 3

      container.scrollLeft = scrollLeftRef.current - walk
    }

    container.addEventListener('mousedown', handleMouseDown)

    return () => {
      container.removeEventListener('mousedown', handleMouseDown)
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [])

  return containerRef
}

const Carousel: FC<CarouselProps> = ({
  items = [],
  className,
  paginatorPosition = 'floating',
  children,
  paginatorColor = 'dark',
  autoChangeInterval,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const [activeIndex, setActiveIndex] = useState(0)
  const containerRef = useHorizontalDragToScroll()
  const allItems = [...items, ...React.Children.toArray(children)]
  const elements = useRef<HTMLDivElement[]>([])
  if (allItems.length === 0) return null

  // https://www.30secondsofcode.org/react/s/use-interval/
  const useInterval = (callback, delay) => {
    const savedCallback = React.useRef(callback)

    React.useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    React.useEffect(() => {
      const tick = () => {
        savedCallback.current()
      }
      if (delay !== null) {
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  // duplicate the first item to create the illusion of an infinite carousel
  const firstItem = Object.assign({}, allItems[0])
  allItems.push(firstItem)

  useInterval(
    () => {
      const nextIndex = activeIndex + 1
      if (nextIndex >= allItems.length) {
        // if we're at the last item, scroll to the first item instantly, creating the illusion of an infinite carousel
        scrollTo(0, 'auto')
        // then scroll to the second item with smooth scrolling
        scrollTo(1)
      } else {
        scrollTo(nextIndex)
      }
    },
    autoChangeInterval && autoChangeInterval > 0 ? autoChangeInterval : null
  )

  // index is the index of the item to scroll to
  // activeIndex is the index of the currently active item
  const scrollTo = (index: number, scrollBehavior?: ScrollBehavior) => {
    elements.current[index]?.scrollIntoView({ behavior: scrollBehavior ?? 'smooth', block: 'nearest', inline: 'nearest' })
  }

  // stupid hack to make the last item scroll instantly to the first item,
  // which wouldn't have been needed if "scroll-behavior: 'instant'" was still working
  const scrollBehavior = activeIndex + 1 === allItems.length && autoChangeInterval > 0 ? 'auto' : 'smooth'

  return (
    <Box className={clsx(styles.container, className)} {...cleanProps}>
      <div ref={containerRef} className={styles.carousel} style={{ scrollBehavior: scrollBehavior }}>
        {allItems.map((item, index) => {
          return (
            <CarouselItem onActive={() => setActiveIndex(index)} key={index} onRefAssigned={(el) => (elements.current[index] = el)}>
              {item}
            </CarouselItem>
          )
        })}
      </div>
      {allItems?.length > 1 ? (
        <Box className={clsx(styles.paginator, styles.paginatorLayout[paginatorPosition])} display="flex" gap="xs" padding="m">
          {allItems.map((_, index) => {
            // with autoChangeInterval, we don't want to show the last paginator item,
            // as it's a duplicate of the first item to create the illusion of an infinite carousel
            if (autoChangeInterval && index === allItems.length - 1) return null

            return (
              <button
                onClick={() => {
                  scrollTo(index)
                }}
                key={`nav-${index}`}
                className={clsx(styles.paginatorItem[paginatorColor], activeIndex === index && styles.paginatorItemActive[paginatorColor])}
                aria-label={`Show image ${index + 1}`}
              />
            )
          })}
        </Box>
      ) : null}
    </Box>
  )
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CarouselItem: FC<{ onActive: () => void; children?: React.ReactNode; onRefAssigned: (element: HTMLDivElement) => void }> = ({
  children,
  onActive,
  onRefAssigned,
}) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { amount: 0.3 })

  useEffect(() => {
    if (isInView) {
      onActive()
    }
  }, [isInView])

  return (
    <div ref={(el) => ((ref.current = el), onRefAssigned(el))} className={styles.item}>
      {children}
    </div>
  )
}

export default Carousel
