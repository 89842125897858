export const trackOutboundLink = (url: string): void => {
  if (!url) return

  if (typeof window?.gtag !== 'undefined') {
    gtag('event', 'outbound', { click: url })
  }
}

type GtagEvent = {
  action: string
  values: Record<string, string>
}

export const trackEvent = (event: GtagEvent): void => {
  if (typeof window?.gtag !== 'undefined') {
    gtag('event', event.action, { ...event.values })
  }
}
