import React, { useEffect, useState } from 'react'
import styles from './Switch.module.scss'

interface SwitchProps {
  defaultChecked?: boolean
  name: string
  onCheck: (isChecked: boolean) => void
}

const Switch: React.FC<SwitchProps> = ({ onCheck, defaultChecked = false }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked)

  useEffect(() => {
    setIsChecked(defaultChecked)
  }, [defaultChecked])

  const handleCheckboxChange = () => {
    const newCheckedValue = !isChecked
    setIsChecked(newCheckedValue)
    onCheck(newCheckedValue)
  }

  return (
    <label className={styles.switch}>
      <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
      <span className={styles.slider}>.</span>
    </label>
  )
}

export default Switch
